<template>
  <v-dialog v-model="dialog" persistent max-width="800">
    <template v-slot:activator="{ on, attrs }">
      <button
        class="btn btn-light-info py-1 poppins"
        v-bind="attrs"
        v-on="on"
        @click="toggleDialog"
      >
        View Instructions
      </button>
    </template>
    <div class="bg-white poppins">
      <div class="modal-body d-flex flex-column h-500px scroll-y mx-5 py-7">
        <v-textarea
          v-model="text"
          label="Instructions"
          class="mt-4"
          height="370"
          outlined
          clearable
          hide-details
        />
        <div class="d-flex flex-grow-1 align-end justify-center">
          <button
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="dialog = false"
          >
            Cancel
          </button>
          <button class="btn btn-info px-5 py-3 ls1" @click="handleSave">
            Save
          </button>
        </div>
      </div>
    </div>
  </v-dialog>
</template>

<script>
export default {
  name: "InstructionTextAreaDialog",
  props: ["instructions"],
  data: () => ({
    dialog: false,
    text: null,
  }),
  methods: {
    handleSave() {
      this.dialog = false;
      this.$emit("save", this.instructions, this.text);
    },
    toggleDialog() {
      this.text = this.instructions;
    },
  },
};
</script>
